import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import{Homecomponent} from './home/home';
import{FooterComponent} from './footer/footer'

const routes: Routes = [
  { path: '',component:Homecomponent},
  { path: 'footer',component:FooterComponent},
 
  
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  
 }
