import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.html',
  styleUrls: ['./header.css']
})

export class Headercomponent implements OnInit {

    constructor() {
    
     
     }
    

  

    ngOnInit(): void {
    }
	
	
}  