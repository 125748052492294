






<section style="width:100%; " class="footer">
    <footer id="sticky-footer" style="background-color:#fcb132; " class="py-4 text-white-50 ">
        <div class="container text-center" >
          <p style="color: white">
            Copyright ©<script>document.write(new Date().getFullYear());</script>2020 All rights reserved | Designed and Maintained by 
            <a href="https://coresoft.herokuapp.com" target="_blank" style="font-weight: bold;color:white;font-size: 15px;">CoreSoft Technology</a>
           
            <br> <span style="color: teal;text-align: center;font-weight: bold;">  Contact : +918129511573 </span>
          </div>

         

      </footer>
</section>




