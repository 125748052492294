import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.html',
  styleUrls: ['./home.css']
})

export class Homecomponent implements OnInit {

    constructor() {
    
     
     }
    

  

    ngOnInit(): void {
    }
	
	
}  