import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { TableModule } from 'ngx-easy-table';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import{ Headercomponent } from './header/header';
import{ Newscomponent } from './news/news';
import{Homecomponent} from './home/home';
import{FooterComponent} from './footer/footer'
@NgModule({
  declarations: [
    AppComponent,Headercomponent,Newscomponent,Homecomponent,FooterComponent
  ],
  imports: [
    BrowserModule,TableModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
