import { Component, OnInit } from '@angular/core';
import { Config, STYLE, THEME,Columns } from 'ngx-easy-table';
import { Hero } from './hero';
import { HEROES } from './mock'

@Component({
  selector: 'app-news',
  templateUrl: './news.html',
  styleUrls: ['./news.css']
})

export class Newscomponent implements OnInit {
  
    
  

    ngOnInit(): void {


      
      
    
  
}
}