

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<app-header> </app-header>


 

<div id="carouselExampleIndicators" class="carousel slide  carousel-fade" data-ride="carousel">
    <ol class="carousel-indicators"> 
        
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="9"></li>
    </ol>
    
    <div class="carousel-inner">
        
      <div class="carousel-item active" >
        <img class="d-block w-100" src="../../assets/images/1.jpg" alt="First slide">
      </div>
      <div class="carousel-item" >
        <img class="d-block w-100" src="../../assets/images/2.jpg" alt="Second slide">
      </div>
      <div class="carousel-item" >
        <img class="d-block w-100" src="../../assets/images/3.jpg" alt="Third slide">
      </div>
      <div class="carousel-item " >
        <img class="d-block w-100" src="../../assets/images/4.jpg" alt="fourth slide">
      </div>
      <div class="carousel-item " >
        <img class="d-block w-100" src="../../assets/images/5.jpg" alt="Fifth slide">
      </div>
      <div class="carousel-item " >
        <img class="d-block w-100" src="../../assets/images/6.jpg" alt="sixth slide">
      </div>
      <div class="carousel-item " >
        <img class="d-block w-100" src="../../assets/images/7.jpg" alt="seven slide">
      </div>
      <div class="carousel-item " >
        <img class="d-block w-100" src="../../assets/images/8.jpg" alt="eight slide">
      </div>
      <div class="carousel-item " >
        <img class="d-block w-100" src="../../assets/images/9.jpg" alt="nine slide">
      </div>
      <div class="carousel-item " >
        <img class="d-block w-100" src="../../assets/images/10.jpg" alt="ten slide">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  


<section style="width:100; background-color: wheat">
   

<div class="onoffswitch3">
    <input type="checkbox" name="onoffswitch3" class="onoffswitch3-checkbox" id="myonoffswitch3" checked>
    <label class="onoffswitch3-label" for="myonoffswitch3">
        <span class="onoffswitch3-inner">
            <span class="onoffswitch3-active">
                <marquee class="scroll-text">പിതൃപൂജ,തിലഹവനം എല്ലാ ദിവസവും രാവിലെ 10 മണിക്ക് ക്ഷേത്രത്തിൽ വച്ച് നടത്തപ്പെടുന്നു  <b>ॐ </b> 
                    സർക്കാരിൻ്റെ  കോവിഡ് ജാഗ്രത നിർദേശം അനുസരിച്ചുകൊണ്ട് ,ഭക്തർക്ക് അവരുടെ പൂജകൾ മുടങ്ങാതെ തന്നെ ക്ഷേത്രത്തിൽ നടത്തുവാനുള്ള സൗകര്യം ഏർപ്പെടുത്തിയിട്ടുണ്ട് . <b>ॐ </b>  <span class="glyphicon glyphicon-forward"></span>
                    ഭക്തർക്ക് പൂജകൾ ഓൺലൈനായി ബുക്ക് ചെയ്യാവുന്നതാണ്<b>ॐ </b>  </marquee>
                <span class="onoffswitch3-switch">ക്ഷേത്ര വാർത്ത  </span>
            </span>
            <span class="onoffswitch3-inactive"><span class="onoffswitch3-switch">കാണുക  BREAKING NEWS</span></span>
        </span>
    </label>
</div>  


</section>
        

  


<section style="width:100%; background-color: wheat;">

    <div class="about-us-area about-page section-padding-30-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-5">
                    <div class="about-content">
                        <h2 class="malayalam">ക്ഷേത്രം</h2>
                        <p class="malayalam text-justify text-size">
                            
കാർത്തികപ്പള്ളിയിലെ ഒരു ചെറിയ ഗ്രാമമാണ് ത്രിക്കുന്നപ്പുഴ. 
പുരാതന രചനകൾ വെളിപ്പെടുത്തുന്നത് വിദേശ രാജ്യങ്ങളിൽ നിന്നുള്ള ധാരാളം ഭക്തരും
 മുൻകാലങ്ങളിൽ ക്ഷേത്രം സന്ദർശിച്ചിരുന്നു എന്നാണ്. ‘സ്വാമിയുടെ’ അനുഗ്രഹം ഈ ഭൂമിയും സമൃദ്ധിയും ഭാഗ്യവും നൽകുന്നു. 
നൂറുകണക്കിന് ഭക്തർക്ക് ആശ്വാസമേകുന്ന ഈ ആരാധനാലയം. 
ഈ ക്ഷേത്രത്തിന് ചുറ്റും രാജവംശത്തിൻ്റെ  നാഡീ കേന്ദ്രം രൂപപ്പെട്ടു.
ശാസ്താ ഈ ദേശം ഭരിക്കുകയും ഭക്തനും കുടുംബത്തിനും അനുഗ്രഹം ചൊരിയുകയും ചെയ്യുന്നുവെന്ന് വിശ്വസിക്കപ്പെടുന്നു.
 ഇവിടെയുള്ള സ്വാമിയുടെ വിഗ്രഹത്തിന് 5000 വർഷത്തിലേറെ പഴക്കമുണ്ടെന്ന് പുരാവസ്തു ഗവേഷകർ പറയുന്നു. 
 സ്വാമിയുടെ വിശുദ്ധ സാന്നിധ്യം രാജ്യത്തിന്റെ വിവിധ ഭാഗങ്ങളിൽ നിന്നുള്ള ധാരാളം ഭക്തരെ ആകർഷിക്കുന്നു.
  
                        </p>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="about-thumbnail">
                        
                        
                        
                        <img src="../../assets/images/about.jpg" alt="TEMPLE">
                    </div>
                </div>
            </div>

        </div>
    </div>

</section>


<!--<section style="width:100%;margin-top: 2px;height:20%; background-color: wheat;">

    <swiper [config]="config">
 
        <div class="swiper-wrapper"> 
       
          <div class="swiper-slide" *ngFor="let slide of slideData">
            <img src="https://i.picsum.photos/id/{{slide.id}}/400/300.jpg" />
            {{slide.name}}
          </div>
       
        </div>
       
        -- Add Pagination --
        <div class="swiper-pagination"></div>
       
        -- Add Arrows -
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
       
      </swiper>
          

</section> -->




<section class="call-to-action-area section-padding-100 bg-img bg-overlay"
 style=" background-color: wheat;
background-image: url(../assets/images/shivabanner.jpg)">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="call-to-action-content text-center">
                    <p class="malayalam" style="font-size: 30px;color:#c92f2f;font-weight: bold;">തൃക്കുന്നപ്പുഴ ശ്രീധർമ്മ ശാസ്താ ക്ഷേത്രം</p>
                    <p class="text-white malayalam" style="font-size: 19px;">
                        ആലപ്പുഴ ജില്ലയിൽ കാർത്തികപ്പള്ളി താലുക്കിൻ്റെ മധ്യഭാഗം വിട്ട് വടക്ക് ഭാഗം ചേർന്ന് കടൽത്തീരത്ത് തൃക്കുന്നപ്പുഴ ഗ്രാമത്തിൽ ചരിത്ര പ്രസിദ്ധമായ പുരാതന ക്ഷേത്രം നിലനിൽക്കുന്നു. കായംകുളത്തു നിന്ന് ഏകദേശം പതിമൂന്നു കിലോമീറ്റർ വടക്ക് മാറിയും ,
                        ഹരിപ്പാട്ടു നിന്ന് ഏകദേശം മൂന്ന് കിലോമീറ്റർ തെക്ക് മാറിയും കാണുന്ന നങ്ങ്യാർകുളങ്ങര കവലയിൽ നിന്ന് ഏകദേശം ഏഴു കിലോമീറ്റർ പടിഞ്ഞാറു ഭാഗത്തേക്ക് യാത്ര ചെയ്‌താൽ ഈ ക്ഷേത്രത്തിൽ എത്തിച്ചേരാം .
                    </p>
                    <br>
                    
                </div>
            </div>
        </div>
    </div>
</section>

<!--temple vivaram-->
    <section class="akame-blog-details-area section-padding-80-0">
        <div class="container" style="margin-top: 10px;">    			
            <div class="row justify-content-center">                
				<div class="col-12">
                    <!-- Section Heading -->
                    <div class="section-heading" style="text-align: center">
                        <h2> ക്ഷേത്ര വിശേഷ ദിവസങ്ങൾ</h2>                        
                    </div>
                    <!-- Choose Us Content -->
                    <div class="choose-us-content mt-30 mb-40">
                        <ul>
                            <li><i class="fa fa-check-square-o" aria-hidden="true"></i>പിതൃപൂജ,തിലഹവനം എല്ലാ ദിവസവും രാവിലെ 10 മണിക്ക് </li>
                            <li><i class="fa fa-check-square-o" aria-hidden="true"></i> എല്ലാ മലയാളമാസം ഒന്നാം തിയതിയും ക്ഷേത്രത്തിൽ അന്നദാനം ഉണ്ടായിരിക്കുന്നതാണ് </li>
                            <li><i class="fa fa-check-square-o" aria-hidden="true"></i> ചിങ്ങമാസത്തിലെ വിനായക ചാതുർത്ഥി </li>
                            <li><i class="fa fa-check-square-o" aria-hidden="true"></i> തുലാമാസത്തിലെ സ്കന്ദഷഷ്ഠി </li>
                            <li><i class="fa fa-check-square-o" aria-hidden="true"></i> വൃശ്ചികമാസത്തിലെ ഉത്രം ആറാട്ട് വരും വിധം പത്തു ദിവസം ഉത്സവം </li>
                            <li><i class="fa fa-check-square-o" aria-hidden="true"></i> ധനു 1 മുതൽ 11 വരെ ചിറപ്പ് </li>
							<li><i class="fa fa-check-square-o" aria-hidden="true"></i> ധനു 11-പള്ളിക്കെട്ടുകളുമായി ധാരാളം ഭക്ത ജനങ്ങൾ ക്ഷേത്രത്തിൽ എത്തിച്ചേരുന്നു </li>
							<li><i class="fa fa-check-square-o" aria-hidden="true"></i> മകരമാസത്തിൽ തൈപ്പൂയം </li>
							<li><i class="fa fa-check-square-o" aria-hidden="true"></i> മിഥുനമാസത്തിലെ പുണർതം നാളിൽ ശ്രീസുബ്രമണ്യസ്വാമിയുടെ പുനഃപ്രതിഷ്ഠാ വാർഷികം </li>
							<li><i class="fa fa-check-square-o" aria-hidden="true"></i> കർക്കിടകമാസത്തിലെ കറുത്തവാവ് </li>
							<li><i class="fa fa-check-square-o" aria-hidden="true"></i>എല്ലാ മാസവും ഷഷ്ഠി </li>
							
							
                        </ul>
                    </div>
                </div>				   
            </div>			
        </div>
    </section>

<!--temple vivaram-->





 <div style="width: 100%; margin-top: 1px;">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d252151.6604751466!2d76.40093660510014!3d9.075633774802824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b08a1b536be0c95%3A0xf355d7b19d575ed1!2sThrikkunnappuzha%20Sree%20Dharmasastha%20Temple!5e0!3m2!1sen!2sin!4v1587815448397!5m2!1sen!2sin"  width="100%" height="200" frameborder="0" style="border:0"></iframe>
 </div>




<!-- ##### Subscribe Area End ##### -->


    
        <br style="width: 100%;background-color: wheat">
       <br style="width: 100%;background-color: wheat">
<section class="contact-area" style="width: 100%;background-color: wheat">
        <div class="container" style="margin-top: 45px;">
            <div class="row">
                <div class="col-12">
                    <div class="contact-content-area">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="contact-content contact-information">
                                    <h4>Contact</h4>
                                    <p>info@sreedharmasastha.org</p>
                                    <p> 0479 2482150 </p>
                                    <p class="text-align malayalam"> <b>പ്രസിഡൻ്റ്: </b>9605052174</p>
                                    <p class="text-align malayalam"> <b>സെക്രട്ടറി : </b>9895065484</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="contact-content contact-information">
                                    <h4>Address</h4>
                                    <p style="font-weight: 600;"> ശ്രീധർമ്മ ശാസ്താ ക്ഷേത്രം  അഖിലഭാരതീയ അയ്യപ്പസേവാ സംഘം ശാഖ-301,തൃക്കുന്നപ്പുഴ, ഫോൺ നമ്പർ-0479 2482150, ഹരിപ്പാട്,ആലപ്പുഴ . </p>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="contact-content contact-information">
                                    <h4 class="malayalam">ദർശന സമയം </h4>
                                    
                                    
                                    
                            

                                    <p><b>രാവിലെ </b> 4.30am - 11.15am</p>
                                    <p><b>വൈകുന്നേരം </b> 5pm - 8.15pm</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
      
    
<section style="width:100%;">
   <app-footer></app-footer>
</section>





