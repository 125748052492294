<header class="header-area">

  <!-- ***** Top Header Area ***** -->
  <div class="top-header" style="background-color: #fcb132;height:100%;">
  
      <div class="container">
          <div class="row">
              <div class="col-12 col-md-6">
                  <div class="top-header-content d-flex flex-wrap align-items-center justify-content-between">
   <!-- Top Header Meta -->
  
                      
<div class="top-header-meta d-flex flex-wrap">
                          
   <a href="http://www.sreedharmasasthatemple.org"
     class="nav-brand">
     <b class="malayalam" style="font-size: 22px;color: red "> ശ്രീധർമ്മ ശാസ്താ ക്ഷേത്രം തൃക്കുന്നപ്പുഴ </b> <br> അഖിലഭാരതീയ അയ്യപ്പസേവാ സംഘം ശാഖ-301</a>
     <p class="pt-3" style="font-size: 12px;color: white;font-weight: bold;font-family:'Times New Roman', Times, serif ;padding-top: 0px;">
                      
      അഖിലഭാരതീയ അയ്യപ്പസേവാ സംഘം ശാഖ-301
  </p>
                   <!-- Social Info -->
                          
                      </div>
                      <!-- Top Header Meta -->

                  </div>
              </div>
              <div class="col-md-6 text-right d-none d-sm-block">
                  <p class="pt-3" style="font-size: 14px;color: rgb(77, 6, 6);font-weight: bold;font-family:'Times New Roman', Times, serif ;margin-top: 20px;">
                      
                      The Official Website of Thrikkunnappuzha Sreedharma Sastha  Temple
                  </p>
                  

              </div>
              
          </div>
      </div>
  </div>
</header>






  
<nav class="navbar mynav sticky-top navbar-expand-lg">
  <a class="navbar-brand" href="#">  <img src="../../assets/svg/logo.png" style="width: 70px;
    height: 60px;" />  </a>
  <button class="navbar-toggler" type="button"
   data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon">   <img src="../../assets/svg/menu_icon.svg" style="width: 25px;
      height: 25px;" /> </span>
  </button>
  
  <div class=".d-block .d-sm-none" ><a class="btn .btn-danger btn-sm" 
    style="font-weight: bold;background-color: bisque;color: black;"  href="https://online.sreedharmasastha.org/pooja-booking"
     role="button" >ONLINE POOJA BOOKING</a></div>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" >
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        
         <!-- <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>  -->
        <a class="nav-link " href="#">ക്ഷേത്രം  <span class="sr-only">(current)</span></a>
      </li>


      <!-- <li class="nav-item"> -->
        <!-- <a class="nav-link" href="#">Temple</a> -->

        <li class="nav-item dropdown ">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown"  
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            ഹോം 
          </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#/about"> എബൗട്ട്  </a>
          <a class="dropdown-item" href="#/history"> ചരിത്രം  </a>
          <a class="dropdown-item" href="#/rennovation"> പുനരുദ്ധാരണം  </a>
          
         
             
        </div>

        </li>



        <li class="nav-item dropdown ">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown"    role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            ആഘോഷം 
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
            <a class="dropdown-item" href="#/sivarathri">കർക്കിടക വാവ് </a>
            
            <a class="dropdown-item" href="#/vinayakacha">വിനായകചതുർത്ഥി </a>
            
            <a class="dropdown-item" href="#/mandalakalam">മണ്ഡലകാലം</a>
           
           
            
          </div>
        </li>



       


        <li class="nav-item dropdown ">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown"    role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            പൂജകൾ 
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
            <a class="dropdown-item" href="#/vazhipad">പിതൃപൂജ </a>
            <a class="dropdown-item" href="#/archana">തിലഹവനം  </a>
            <a class="dropdown-item" href="#/homam">ഗണപതി ഹോമം </a>
            <a class="dropdown-item" href="#/maharudram">മൃത്യുഞ്ജയ ഹോമം  </a>
            <a class="dropdown-item" href="#/poojatime">നവഗ്രഹപൂജ </a>
            
           
            
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#/news">പിതൃ ദർപ്പണം </a> 
        </li>
        
        
        <li class="nav-item dropdown ">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown"    role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            ഉപദേവത
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
            <a class="dropdown-item" href="#/hanuman">സുബ്രമണ്യൻ </a>
            <a class="dropdown-item" href="#/hanuman">ശിവൻ  </a>
            <a class="dropdown-item" href="#/hanuman">നവഗ്രഹം  </a>
            <a class="dropdown-item" href="#/nagarajav">കൃഷ്ണൻ  </a>
            <a class="dropdown-item" href="#/Yakshiyamma">ഭദ്ര  </a>
            <a class="dropdown-item" href="#/Ganapathi">ഗണപതി</a>
           
            
            
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#/gallery">ഗ്യാലറി </a> 
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#/contact">ബന്ധപ്പെടുക </a> 
        </li>  


    </ul>
    <a class="navbar-brand" href="#">  <img src="../../assets/svg/logo1.png" style="width: 70px;
      height: 60px;" />  </a>
  </div>
</nav>  






